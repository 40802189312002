
import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IDashboardAnalysis, IDashboardAnalysisSumAmount} from '@/types/IDashboardTypes';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

@Component({name: 'DashboardAnalysisTable'})
export default class DashboardAnalysisTable extends Vue {
  @Prop()
  type!: string;

  @Prop()
  data!: IDashboardAnalysis[];

  @Prop()
  loading!: boolean;

  @Prop()
  setInfoBlock!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_KEY',
      value: 'key',
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_NET',
      value: 'sumAmount.net',
      align: 'end',
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_VAT',
      value: 'sumAmount.vat',
      align: 'end',
    },
    {
      text: 'SYSTEM_SUM_AMOUNT_BRUTTO',
      value: 'sumAmount.gross',
      align: 'end',
    },
    {
      text: 'SYSTEM_QUANTITY_OF_ORDERS',
      value: 'quantityOfOrders',
      align: 'center',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_ORDERS_PER_DAY',
      value: 'averageOfOrdersPerDay',
      align: 'center',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_NET',
      value: 'averageOfAmountPerDay.net',
      align: 'end',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_VAT',
      value: 'averageOfAmountPerDay.vat',
      align: 'end',
    },
    {
      text: 'SYSTEM_AVERAGE_OF_AMOUNT_PER_DAY_BRUTTO',
      value: 'averageOfAmountPerDay.gross',
      align: 'end',
    },
  ];

  sumField(key: string) {
    const data: IDashboardAnalysisSumAmount[] = this.data;

    data.forEach((el) => {
      el.sumAmountNet = el.sumAmount.net;
      el.sumAmountVat = el.sumAmount.vat;
      el.sumAmountGross = el.sumAmount.gross;
      el.averageOfAmountPerDayNet = el.averageOfAmountPerDay.net;
      el.averageOfAmountPerDayVat = el.averageOfAmountPerDay.vat;
      el.averageOfAmountPerDayGross = el.averageOfAmountPerDay.gross;
    });

    // @ts-ignore
    return data.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
  }

  created() {
    this.headers[0].text = this.$t(this.type) as string;

    if (this.setInfoBlock) {
      this.headers.unshift({
        text: '',
        value: 'info',
        sortable: false,
        width: '0px',
      });
    }
  }

  formatNumber(value: number) {
    return formatNumberWithSpaces(value, 2);
  }
}
